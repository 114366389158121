<template>
    <div>
        <van-list
                v-model="loading"
                :finished="finished"
                :finished-text="finishedText"
                :immediate-check="false"
                @load="onLoad">
            <div class="main_list" v-for="(item,index) in list" :key="index">
                <div class="main_list_bottom">
                    <div class="main_list_info">
                        <div class="main_list_content">
                            <span>[车牌号] {{ item.licensePlate }}</span>
                            <span class="main_list_type" v-show="item.parkingPlaceNumber">车位编号 [ {{ item.parkingPlaceNumber }} ]</span>
                        </div>
                        <div class="main_list_xinxi">
                            <span>车场名称：{{ parkingName }} · {{ item.cycleType == 1 ? '月卡' : item.cycleType == 2 ? '季卡' :
                                item.cycleType == 3 ?  '半年卡' : item.cycleType == 4 ? '年卡': item.cycleType == 5 ? '长期卡' : ''}}</span>
                            <span>缴费金额：{{ item.payMoney ? item.payMoney : 0 }}元</span>
                            <span>有效期至：{{ item.dueTime }}</span>
                            <span>续费时间：{{item.payTime}}</span>
                        </div>
                    </div>
                </div>
            </div>
        </van-list>
        <div class="placeholder-div"></div>
        <div class="botton-div">
            <div>
                <van-button class="botton-style" v-if="btn" type="primary" @click="handleReturn">返 回</van-button>
                <van-button class="botton-style" v-if="btn" type="primary" @click="handleAdd">续 费</van-button>
            </div>
            <div class="bottom-style-text">
                <span class="p-text2">Copyright@2023 All Rights Reserved.</span>
            </div>
        </div>
        <van-popup
                v-model="show"
                position="bottom"
        >
            <van-picker
                    title="选择周期数量"
                    show-toolbar
                    :columns="columns"
                    @confirm="onConfirm"
                    @cancel="onCancel"
                    @change="onChange"
            />
        </van-popup>
        <van-popup
                v-model="showPay"
                position="bottom"
                :style="{ height: '307.98px'}">
            <div class="van-popup-pay">
                <div class="van-popup-head">
                    <van-button @click="handleBack" class="van-button-return">返回
                    </van-button>
                    <div class="van-head-title">支付确认
                    </div>
                    <van-button v-show="loadingbt == false" @click="handleBinding" class="van-button-pay1"
                                loading-type="spinner"
                                :loading="loadingbt"
                                loading-text="支付中">支付
                    </van-button>
                    <van-button v-show="loadingbt == true" class="van-button-pay2" loading-type="spinner"
                                :loading="loadingbt"
                                loading-text="支付中">支付
                    </van-button>
                </div>
                <div class="van-content">
                    <div class="van-content-infos">
                        <span class="title-color">续费时长</span>
                        <span>{{orderInfo.days}}天</span>
                    </div>
                    <div class="van-content-infos">
                        <span class="title-color">续费至</span>
                        <span>{{orderInfo.endTime}}</span>
                    </div>
                    <div class="van-content-infos">
                        <span class="title-color">待支付</span>
                        <span>{{orderInfo.paidAmount}}元</span>
                    </div>
                </div>
            </div>
        </van-popup>

        <van-popup v-model="showPop" class="ad-popup" :close-on-click-overlay="false">
            <div style="margin-bottom: 20px;font-size: 18px;color: white;">关闭弹窗，进入缴费页面</div>
            <van-swipe :autoplay="3000">
                <van-swipe-item v-for="(image, index) in adImg" :key="index">
                    <img v-lazy="image.url" @click="handleClick"/>
                </van-swipe-item>
            </van-swipe>
            <van-icon style="margin-top: 20px" size="40px" name="clear" @click="showPop = false"/>
        </van-popup>
    </div>
</template>

<script>
    import {Button, Dialog, Empty, Icon, List, Picker, Popup, PullRefresh, Swipe, SwipeItem, Toast} from 'vant'
    import Vue from 'vue';
    import {Lazyload} from 'vant';

    Vue.use(Lazyload);
    import debounce from "../directives/debounce";
    import {
        carBind,
        carBinded,
        getAdvertisement,
        getParking,
        longCarRenew,
        longOrderInfo,
        longPayRecord
    } from "../api/interface";

    export default {
        name: "longRent",
        components: {
            [Button.name]: Button,
            [Empty.name]: Empty,
            [List.name]: List,
            [PullRefresh.name]: PullRefresh,
            [Icon.name]: Icon,
            [Popup.name]: Popup,
            [Picker.name]: Picker,
            [Swipe.name]: Swipe,
            [SwipeItem.name]: SwipeItem,
        },
        data() {
            return {
                show: false,
                showPay: false,
                columns: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10'],
                showNull: false,
                btn: true,
                orderInfo: "",
                cycleNum: "",
                paidAmount: "",
                licensePlate: "",
                cycleType: "",
                payTime: "",
                payMoney: "",
                dueTime: "",
                parkingPlaceNumber: "",
                parkingName: "",
                list: [],
                finished: false,
                error: false,
                loading: false,
                total: 0,
                finishedText: "没有更多了",
                loadingText: "加载中",
                current: 1,
                size: 10,
                weChatParameter: "",
                showPop: false,
                adImg: [],
                targeType: "",
                target: "",
                loadingbt: false,
                payTimes: ""
            }
        },
        mounted() {
            this.parkingName = this.$route.query.parkingName;
            this.getLongPayRecord();
            this.advertisement();
            this.getCurrentTime();
            getParking(this.$route.query.parkingId).then((res) => {
                sessionStorage.setItem('projectId', res.data.data.projectId);
            })
        },
        methods: {
            advertisement() {
                let param = {
                    parkingId: this.$route.query.parkingId,
                    location: 9
                }
                getAdvertisement(param).then(res => {
                        if (res.data.code == 'SUCCESS') {
                            if (res.data.data.materias.length > 0) {
                                this.showPop = true;
                            }
                            this.adImg = res.data.data.materias;
                            this.targeType = res.data.data.targeType;
                            this.target = res.data.data.target;
                        }
                    }
                )
            },
            handleClick() {
                if (this.targeType == 0) {
                    window.location.href = this.target;
                }
            },
            getLongPayRecord() {
                let param = {
                    current: this.current,
                    size: this.size,
                    carNumber: this.$route.query.licensePlate,
                    id: this.$route.query.id
                }
                longPayRecord(param).then((res) => {
                    if (res.data.code === "SUCCESS") {
                        // 这里将拿到的数组合并到老数组，不能直接赋值，必须是合并
                        // 此为按时间倒序，如若想升序，就颠倒一下b、a的位置
                        // res.data.data.records.sort((a, b) => new Date(b.createTime).getTime() - new
                        // Date(a.createTime).getTime());
                        this.list = this.list.concat(res.data.data.records);
                        // 赋值当前页、总页数、总数量
                        this.current = res.data.data.current;
                        this.total = res.data.data.total;
                        this.size = res.data.data.size;

                        // 取消loading效果
                        this.loading = false;

                        // 如果当前数组的长度超过总信息条数，不再触发滚动
                        if (this.list.length >= this.total) {
                            // 加载结束，不再触发滚动加载
                            this.finished = true;
                        }
                    }
                })
            },
            onLoad() {
                this.current++;
                this.getLongPayRecord();
            },
            onRefresh() {
                this.getLongPayRecord();
            },
            handleReturn() {
                this.$router.push('/index')
            },
            handleAdd() {
                this.show = true;
            },
            onConfirm(value, index) {
                this.showPay = true;
                this.show = false;
                this.cycleNum = value;
                let param = {
                    cycleNum: value,
                    id: this.$route.query.id
                }
                longOrderInfo(param).then((res) => {
                    this.orderInfo = res.data.data;
                    this.paidAmount = res.data.data.paidAmount;
                })
            },
            onChange(picker, value, index) {
            },
            onCancel() {
                Toast('取消');
                this.show = false;
            },
            handleBack() {
                this.showPay = false;
                this.show = true;
            },
            handleBinding() {
                if (sessionStorage.getItem('payType') == 1) {
                    let param = {
                        licensePlate: this.licensePlate,
                        openId: sessionStorage.getItem('openId'),
                        userType: sessionStorage.getItem('payType'),
                        userId: sessionStorage.getItem('userId')
                    }
                    carBinded(param).then(res => {
                        if (res.data.data == true) {
                            this.loadingbt = true;
                            this.handleConfirm();
                        } else {
                            Dialog.confirm({
                                title: '温馨提示',
                                message: '绑定车牌后您可以得到停车场出入场信息通知，停车及缴费记录更有迹可循，是否绑定？',
                                confirmButtonColor: '#DC5040',
                            }).then(() => {
                                this.loadingbt = true;
                                this.handleCarBind();
                                this.handleConfirm();
                            }).catch(() => {
                                this.handleCarBind();
                                this.loadingbt = false;
                            });
                        }
                    })
                } else if (sessionStorage.getItem('payType') == 2) {
                    this.loadingbt = true;
                    this.handleConfirm();
                }
            },
            handleCarBind() {
                let param = {
                    licensePlate: this.licensePlate,
                    openId: sessionStorage.getItem('openId'),
                    userType: sessionStorage.getItem('payType'),
                    userId: sessionStorage.getItem('userId')
                }
                carBind(param).then(res => {
                    console.log(res.data.data)
                })
            },
            handleConfirm: debounce(function () {
                let that = this;
                that.show = false;
                setTimeout(function () {
                    that.loadingbt = false;
                }, 4000);
                let param = {
                    payMoney: that.paidAmount,
                    cycleNum: that.cycleNum,
                    id: that.$route.query.id,
                    payType: sessionStorage.getItem('payType')
                }
                longCarRenew(param).then((res) => {
                    if (res.data.code == 'SUCCESS') {
                        that.weChatParameter = JSON.parse(res.data.data.payInfo);
                        if (res.data.data.errorCode) {
                            if (res.data.data.errorCode == "TL_AMOUNT_ZERO") {
                                //支付地址为空和支付信息为空就直接跳转支付完成页
                                this.$router.push({
                                    path: '/pay/long/finish', query: {
                                        parkingId: that.$route.query.parkingId,
                                        parkingName: that.$route.query.parkingName,
                                        amount: that.paidAmount,
                                        licensePlate: that.$route.query.licensePlate,
                                        payTime: that.payTimes
                                    }
                                });
                            } else if (res.data.data.errorCode == "TL_PAY_EXPIRE") {
                                alert('超时，请重新扫码！')
                            }
                        } else {
                            if (res.data.data.payUrl != null) {
                                //跳转地址不为空，第三方渠道是pp或者ps就直接跳转第三方支付地址
                                if (res.data.data.thirdChannel == "pp" || res.data.data.thirdChannel == "ps") {
                                    window.location.href = res.data.data.payUrl;
                                }
                            } else if (res.data.data.payChannel == 1 && res.data.data.thirdChannel == "cl") {
                                //第三方渠道是cl，支付渠道是微信，调用微信支付
                                that.weixinPay();
                            }
                            that.orderNo = res.data.data.orderNo;
                        }
                    } else if (res.data.code == "NEED_PAY") {
                        Dialog.confirm({
                            title: '温馨提示',
                            message: '是否缴纳未支付的费用再续期？\n' +
                                '如果是，跳转临时车缴费页面',
                            confirmButtonColor: '#DC5040',
                        }).then(() => {
                            that.$router.push({
                                path: '/temporary', query: {
                                    parkingId: res.data.data.parkingId,
                                    licensePlate: res.data.data.licensePlate,
                                    type: 'longCar',
                                }
                            });
                        }).catch(() => {
                            Toast('已取消');
                        });
                    } else {
                        Toast(res.data.message)
                    }
                    that.showPay = false;
                }).catch(() => {
                    that.paidAmount = "";
                    that.cycleNum = "";
                    that.showPay = false;
                })
            }, 100),
            getCurrentTime() {
                //获取当前时间并打印
                let yy = new Date().getFullYear();
                let mm = new Date().getMonth() + 1;
                let dd = new Date().getDate();
                let hh = new Date().getHours();
                let mf = new Date().getMinutes() < 10 ? '0' + new Date().getMinutes() : new Date().getMinutes();
                let ss = new Date().getSeconds() < 10 ? '0' + new Date().getSeconds() : new Date().getSeconds();
                this.payTimes = yy + '-' + mm + '-' + dd + ' ' + hh + ':' + mf + ':' + ss;
            },
            // 解决微信内置对象报错
            weixinPay(params) {
                let that = this;
                if (typeof WeixinJSBridge == "undefined") {
                    if (document.addEventListener) {
                        document.addEventListener('WeixinJSBridgeReady', that.onBridgeReady(params), false);
                    } else if (document.attachEvent) {
                        document.attachEvent('WeixinJSBridgeReady', that.onBridgeReady(params));
                        document.attachEvent('onWeixinJSBridgeReady', that.onBridgeReady(params));
                    }
                } else {
                    that.onBridgeReady();
                }
            },
            //微信内置浏览器类，weChatParameter对象中的参数是3.步骤代码中从后端获取的数据
            onBridgeReady() {
                let that = this;
                let timestamp = Math.round(that.weChatParameter.timeStamp).toString();
                WeixinJSBridge.invoke(
                    'getBrandWCPayRequest', {
                        "appId": that.weChatParameter.appId,     //公众号名称，由商户传入
                        "timeStamp": timestamp, //时间戳，自1970年以来的秒数
                        "nonceStr": that.weChatParameter.nonceStr, //随机串
                        "package": that.weChatParameter.package,
                        "signType": that.weChatParameter.signType, //微信签名方式：
                        "paySign": that.weChatParameter.paySign, //微信签名
                    },
                    function (res) {
                        // 使用以上方式判断前端返回,微信团队郑重提示：res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
                        if (res.err_msg == "get_brand_wcpay_request:ok") { //支付成功后的操作
                            Toast("支付成功");
                        } else if (res.err_msg == 'get_brand_wcpay_request:cancel') { //取消支付的操作
                            Toast("取消支付");
                        } else { //支付失败的操作
                            Toast("支付失败");
                        }
                    }
                );
            },
        },
        beforeRouteEnter(to, from, next) {
            // 添加背景色
            document.querySelector('body').setAttribute('style', 'background-color:#FFF')
            next()
        },
        beforeRouteLeave(to, from, next) {
            // 去除背景色
            document.querySelector('body').setAttribute('style', '')
            next()
        }
    }
</script>

<style scoped>
    .main_list {
        background-color: #F9F9F9;
        margin: 10px;
        border-radius: 10px;
    }

    .main_list_bottom {
        display: flex;
        line-height: 30px;
    }

    .main_list_info {
        width: 100%;
        font-size: 18px;
        display: flex;
        flex-direction: column;
    }

    .main_list_content {
        display: flex;
        justify-content: space-between;
        font-size: 16px;
        background-color: #c7c7c738;
        padding: 5px 0;
    }

    .main_list_content span {
        font-weight: bold;
        font-family: "微软雅黑 Light";
        margin-right: 15px;
        margin-left: 15px;
    }

    .main_list_type {
        font-size: 13px;
        background: #545f67;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }

    .main_list_xinxi {
        display: flex;
        flex-direction: column;
        text-align: left;
        margin: 10px 15px;
    }

    .main_list_xinxi span {
        font-size: 14px;
    }

    .botton-div {
        background-color: rgb(255, 255, 255);
        position: fixed;
        bottom: 0;
        width: 100%;
        height: 120px;
    }

    .botton-div div {
        display: flex;
        justify-content: center;
    }

    .botton-style {
        width: 50%;
        margin: 20px;
        font-size: 16px;
        background: linear-gradient(-30deg, #DC5040, #EC7955);
        border: none;
        font-weight: 500;
    }

    .placeholder-div {
        width: 100%;
        height: 110px;
    }

    .bottom-style-text {
    }

    .bottom-style-text .p-text2 {
        font-family: 微软雅黑;
        font-size: 12px;
        color: #666666;
    }

    .van-popup-pay {
        position: relative;
    }

    .van-popup-head {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 1.17333rem;
    }

    .van-button-return {
        height: 100%;
        width: 2rem;
        padding: 0;
        margin: 0;
        font-size: 13px !important;
        background-color: transparent !important;
        border: none;
        cursor: pointer;
        color: #969799;
    }

    .van-button-pay1 {
        height: 100%;
        width: 2rem;
        padding: 0;
        margin: 0;
        font-size: 13px !important;
        background-color: transparent !important;
        border: none;
        cursor: pointer;
        color: #576b95;
    }

    .van-button-pay2 {
        height: 100%;
        width: 3.5rem;
        padding: 0;
        margin: 0;
        font-size: 13px !important;
        background-color: transparent !important;
        border: none;
        cursor: pointer;
        color: #576b95;
    }

    .van-head-title {
        width: 100%;
        font-weight: 500;
        font-size: 0.42667rem;
        line-height: 0.53333rem;
        text-align: center;
    }

    .van-content {
        display: flex;
        margin: 10px;
        line-height: 65px;
        flex-direction: column;
    }

    .van-content-infos {
        font-size: 16px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    .title-color {
        color: #8a8a8a;
    }

    /*.van-button {*/
    /*    margin: 10px;*/
    /*    font-size: 16px;*/
    /*    background: linear-gradient(-30deg, #EC7955, #DC5040);*/
    /*    border-radius: 27px;*/
    /*    border: none;*/
    /*    font-weight: 500;*/
    /*}*/
    /deep/ .van-overlay {
        background-color: rgba(0, 0, 0, .2);
        backdrop-filter: blur(6px);
    }

    /deep/ .van-popup--center {
        width: 100%;
    }

    .ad-popup {
        background-color: unset !important;
    }

    .ad-popup img {
        width: 70%;
    }
</style>
